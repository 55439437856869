import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="chat"
export default class extends Controller {
  static targets = ["inner", "input"];
  connect() {
    if (this.hasInnerTarget) {
      const scrollableElement = this.innerTarget;
      const contentElement = this.innerTarget;

      function scrollToBottom() {
        scrollableElement.scrollTop = scrollableElement.scrollHeight;
        window.scrollTo({
          top: document.body.scrollHeight,
        });
      }

      const observer = new MutationObserver((mutations) => {
        scrollToBottom();
      });

      const config = {
        childList: true,
        subtree: true,
      };

      observer.observe(contentElement, config);

      scrollToBottom();
    }

    setTimeout(() => {
      window.scrollTo({
        top: document.body.scrollHeight,
      });
    }, 500);
  }

  scroll() {
    setTimeout(() => {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: "smooth",
      });

      if (this.hasInnerTarget)
        this.innerTarget.scrollTo({
          top: document.body.scrollHeight,
          behavior: "smooth",
        });
    }, 500);
  }

  clear(e) {
    if (!this.hasInputTarget || !e.detail.success) return;

    this.inputTarget.value = "";
  }
}
